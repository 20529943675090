<template>
  <v-card class="mx-auto" max-width="500">
    <v-dialog v-model="addDialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
          class="float-right"
        >
          Add Platform
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Add Platform
        </v-card-title>
        <v-container>
          <v-text-field
            label="Platform Name"
            :rules="rules"
            v-model="platformAdd.name"
            hide-details="auto"
          ></v-text-field>
        </v-container>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="addPlatform"
            :disabled="platformAdd.name === ''"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="updateDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Update Platform
        </v-card-title>
        <v-container>
          <v-text-field
            label="Platform Name"
            :rules="rules"
            v-model="platformUpdate.name"
            hide-details="auto"
          ></v-text-field>
        </v-container>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="updatePlatform"
            :disabled="platformUpdate.name === ''"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-list subheader>
      <v-subheader>Platforms</v-subheader>

      <v-list-item v-for="platform in platformList" :key="platform.id">
        <v-list-item-content>
          <v-list-item-title v-text="platform.name"></v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon>
          <v-icon @click="() => openUpdateDialog(platform)">
            mdi-pencil-outline
          </v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import getAxios from "services/axios-get";
import postAxios from "services/axios-post";
import putAxios from "services/axios-put";

export default {
  data() {
    return {
      platformList: [],
      platformAdd: {
        name: "",
      },
      platformUpdate: {
        id: "",
        name: "",
      },
      addDialog: false,
      updateDialog: false,
      rules: [value => !!value || "Required."],
    };
  },
  mounted() {
    this.fetchPlatforms();
  },
  methods: {
    fetchPlatforms() {
      const self = this;
      getAxios(`${process.env.VUE_APP_SERVER_URL}/platform`)
        .then(res => {
          self.platformList = res.data.content;
        })
        .catch(err => {
          console.error(err);
        });
    },
    addPlatform() {
      const self = this;
      const { platformAdd } = this;
      postAxios(`${process.env.VUE_APP_SERVER_URL}/platform`, {
        name: platformAdd.name,
      })
        .then(res => {
          console.log(res);
          self.fetchPlatforms();
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          self.addDialog = false;
        });
    },
    updatePlatform() {
      const self = this;
      const { id, name } = this.platformUpdate;
      putAxios(`${process.env.VUE_APP_SERVER_URL}/platform/${id}`, {
        name,
      })
        .then(res => {
          console.log(res);
          self.fetchPlatforms();
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          self.updateDialog = false;
        });
    },
    openUpdateDialog(platform) {
      this.updateDialog = true;
      this.platformUpdate.id = platform.id;
      this.platformUpdate.name = platform.name;
    },
  },
};
</script>
